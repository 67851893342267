<template>
  <div :style="getElementStyle">
    <div id="app">
      <el-col
        :span="showLabel && !isDefaultPos ? 4 : data.description ? 20 : 24"
        ref="colRef"
        class="bottom-space"
      >
        <icons
          v-if="isIconRequired"
          :iconName="data.icon_name"
          class="mr-1"
        ></icons>
        <label for="text" :style="getLabelStyles">{{
          label || data.label
        }}</label>
        <span
          v-if="data.validations?.required"
          style="color: red; font-weight: bold"
        >
          *
        </span>
        <span v-if="data.description">
          <i class="el-icon-info" :title="data.description"></i>
        </span>
      </el-col>

      <!-- Text Input -->
      <el-input
        v-model="form[data.key]"
        v-if="!data.validations?.mapViewRequired"
        :readonly="true"
      ></el-input>

      <!-- Map Section -->
      <div
        v-else
        class="map-container"
        style="border: 2px solid cadetblue; border-radius: 10px; flex-grow: 1"
      >
        <div class="mapResource" v-if="data.validations?.AllowEdit">
          <el-input
            v-if="!isView"
            v-model="address"
            placeholder="Enter Address"
          ></el-input>
          <el-button
            v-if="!isView"
            @click="getCoordinates(address)"
            type="primary"
          >
            Search Address
          </el-button>
        </div>
        <l-map :zoom="zoom" :center="center" style="height: 100%">
          <l-tile-layer :url="url"></l-tile-layer>
          <l-marker :lat-lng="coordinates" :icon="markerIcon"></l-marker>

          <!-- Geofence circle -->
          <l-circle
            v-if="
              data.validations?.geoFenceCoordinates &&
              data.validations.geoFenceCoordinates.length === 2
            "
            :lat-lng="data.validations.geoFenceCoordinates"
            :radius="data.validations.geoFenceRadius || 0"
            :color="'red'"
            :fill-color="'red'"
            :fill-opacity="0.3"
          ></l-circle>
        </l-map>
      </div>
    </div>
  </div>
</template>

<script>
import { LMap, LTileLayer, LMarker, LCircle } from "vue2-leaflet";
import { Icon } from "leaflet";
import "leaflet/dist/leaflet.css";
import axios from "axios";
import userPermissionsHelper from "@/mixins/userPermissionsHelper";

export default {
  name: "App",
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LCircle,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    form: {
      type: Object,
      required: true,
    },
    isView: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: "",
    },
    isIconRequired: {
      type: Boolean,
      default: false,
    },
  },
  mixins: [userPermissionsHelper],
  data() {
    return {
      address: "",
      zoom: 10,
      showLabel: true,
      isDefaultPos: true, // Fixed typo
      center: [17.4065, 78.4772], // default center coordinates
      url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
      coordinates: [17.4065, 78.4772],
      markerIcon: new Icon({
        iconUrl: require("leaflet/dist/images/marker-icon.png"),
        iconSize: [25, 41],
        iconAnchor: [12, 41],
        popupAnchor: [1, -34],
        shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
        shadowSize: [41, 41],
      }),
    };
  },
  computed: {
    combinedCoordinates() {
      return `${this.coordinates[0]}, ${this.coordinates[1]}`;
    },
    getLabelStyles() {
      return this.data.styles?.label_color
        ? `color: ${this.data.styles.label_color}`
        : "font-weight: 700";
    },
    getElementStyle() {
      return {
        display: "flex",
        flexDirection: "column",
        borderRadius: "50px",
        padding: "10px",
        backgroundColor: "#f9f9f9",
      };
    },
  },
  methods: {
    async getCoordinates(address) {
      try {
        if (!address) {
          console.error("Address is required");
          return;
        }

        const config = {
          params: {
            address,
            sensor: true,
            key: process.env.VUE_APP_GOOGLE_MAPS_API_KEY,
          },
        };

        const response = await axios.get(
          "https://maps.googleapis.com/maps/api/geocode/json",
          config
        );

        if (response.data?.results?.[0]?.geometry?.location) {
          const { lat, lng } = response.data.results[0].geometry.location;
          this.coordinates = [lat, lng];
          this.center = [lat, lng];
          this.$set(this.form, this.data.key, `${lat}, ${lng}`);
        } else {
          console.error("Invalid coordinates response:", response.data);
        }
      } catch (error) {
        console.error("Error fetching coordinates:", error);
      }
    },
    getUserLocation() {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            this.coordinates = [
              position.coords.latitude,
              position.coords.longitude,
            ];
            this.center = this.coordinates;
            this.$set(
              this.form,
              this.data.key,
              `${this.coordinates[0]}, ${this.coordinates[1]}`
            );
          },
          (error) => {
            console.error("Error getting location:", error);
          }
        );
      } else {
        console.error("Geolocation is not supported by this browser.");
      }
    },
  },
  mounted() {
    delete Icon.Default.prototype._getIconUrl;
    Icon.Default.mergeOptions({
      iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
      iconUrl: require("leaflet/dist/images/marker-icon.png"),
      shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
    });
  },
};
</script>

<style scoped>
#app {
  border-radius: 50px;
  display: flex;
  flex-direction: column;
}

.map-container {
  flex-grow: 1;
  margin: 10px 0;
}

.mapResource {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.bottom-space {
  margin-bottom: 10px;
}

.el-button {
  border: none;
  background-color: cadetblue;
  color: white;
  padding: 5px 15px;
  border-radius: 15px;
  margin-top: 5px;
}

.el-icon-info {
  color: cadetblue;
  cursor: pointer;
}

.vue2leaflet-map {
  height: 100%;
  width: 100%;
  position: absolute !important;
}
</style>
